<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class='col'>
          <h5 class="page-header">
            {{ profileOwner.name }}'s Profile
          </h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <div class='row px-5 pt-4'>
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <ValidationObserver ref='form' v-slot="{ }">
            <form @submit.prevent="updateProfile">
              <h5>Profile</h5>

              <BaseFormGroup :label="'Name'" >
                <ValidationProvider v-slot="{ errors }" name='Name' rules='required' >
                  <BaseInput v-model="profileOwner.name" type="text" name="name" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Email'" >
                <ValidationProvider v-slot="{ errors }" name='Email' rules='required|email' >
                  <BaseInput v-model="profileOwner.email" type="text" name="email" :error="errors[0]" :disabled='true' />
                </ValidationProvider>
              </BaseFormGroup>

              <!-- <BaseFormGroup :label="'Phone'" >
                <ValidationProvider v-slot="{ errors }" name='Phone' rules='required' >
                  <BaseInput v-model="profileOwner.phone" type="text" name="phone" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup> -->

              <BaseFormGroup>
                <ValidationProvider v-slot="{ errors }" name='Privacy policy' rules="{ required: { allowFalse: false } }" >
                  <BaseInput type="checkbox" name="toc" :error="errors[0]"><span>I agree to the <a class="link-primary" href="https://outcomms.io/privacy">privacy policy</a></span></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup>
                <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success mt-5 '" >
                  <span>Save</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <div v-if="user.role.name === 'SYSADMIN'" class="row mt-5">
        <div class="col">
          <div class='card'>
            <div class='card-body bg-light'>
              <h5 class="card-title">SYSADMIN debug</h5>
              <p class='card-text'>
                Access Token:
                <code class="text-break">{{ accessToken }}</code>
                <br />
                User:
                <pre><code class="text-break">{{ profileOwner }}</code></pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from '@layouts/main'
import {
  GetUser,
  LocalGetSelf,
  UpdateProfile
} from '@gql/user'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

export default {
  page() {
    const name = this.profileOwner ? this.profileOwner.name : 'Profile'

    return {
      title: name,
      meta: [
        {
          name: 'description',
          content: `The user profile for ${name}.`,
        },
      ],
    }
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      profileOwner: undefined,
      alert: {}
    }
  },
  computed: {
    isOwner() {
      return this.user && this.user.id === this.id
    },
    accessToken() {
      const tokenString = window.localStorage.getItem('access_token')
      return tokenString
    },
  },
  apollo: {
    user: LocalGetSelf,
    profileOwner: {
      query: GetUser,
      variables() {
        return { id: this.id }
      },
      update: data => data.user,
      result({ data: { user } }) {
        if (!user) {
          this.$router.push({ name: '404', params: { resource: 'User' } })
        }
      },
    },
  },
  methods: {
    async updateProfile() {
      this.isLoading = true

      // Validate the form
      if(!await this.$refs.form.validate()) {
        this.isLoading = false
        this.alert.type = 'error'
        this.alert.title = 'Error'
        this.alert.message = 'Please check the form below for errors'
        return false
      }
      // Validation good, reset
      this.$refs.form.reset()

      this.$apollo.mutate({
        mutation: UpdateProfile,
        variables: {
          id: this.id,
          name: this.profileOwner.name,
          // phone: this.profileOwner.phone,
        },
        update: (store, { data: { updateUser }}) => {}
      }).then(data => {
        this.alert.type = 'success'
        this.alert.title = 'Success'
        this.alert.message = 'User updated'
        this.isLoading = false
        this.$refs.form.reset()

        // If the user is not an admin we redirect to the new request form
        if (!this.user.isAdminUser) {
          this.$router.push({ name: 'briefs.new'})
        } else {
          this.$router.push({ name: "home" })
        }
      }).catch((err) => {
        // Error
        this.alert.type = 'error'
        this.alert.title = 'There was a problem'
        this.alert.message = err
      })
    }
  }
}
</script>